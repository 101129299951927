import { apply, put } from 'redux-saga/effects';
import { authActions } from 'bus/auth/actions';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { displayError } from '../../../../helpers';

export function* getOrganizationsWorker ({ payload }) {
    const {authToken, source} = payload;

    try {
        yield put(uiActions.startFetchingGetOrg());

        const {data: {data: organizations}} = yield apply(api, api.auth.getOrganizations, [authToken, source]);
        yield put(authActions.setOrganizations(organizations));
        yield put(authActions.setLoginStep(3));

    } catch (error) {
        displayError('getOrganizationsWorker', error);
    } finally {
        yield put(uiActions.stopFetchingGetOrg());
    }
}
