// Core
import { Map } from 'immutable';

// Types
import { types } from './types';

const initialState = Map({
    isFetching: false,
    isFetchingGetOrg: false,
    isFetchingLogin: false,
    isFetchingLoginOrg: false,
    isFetchingCheckUser: false,
    isFetchingLogout: false,
});

export default (state = initialState, action) => {
    switch (action.type) {
        case types.START_FETCHING:
            return state.set('isFetching', true);

        case types.STOP_FETCHING:
            return state.set('isFetching', false);

        case types.START_FETCHING_CHECK_USER:
            return state.set('isFetchingCheckUser', true);

        case types.STOP_FETCHING_CHECK_USER:
            return state.set('isFetchingCheckUser', false);

        case types.START_FETCHING_GET_ORG:
            return state.set('isFetchingGetOrg', true);

        case types.STOP_FETCHING_GET_ORG:
            return state.set('isFetchingGetOrg', false);

        case types.START_FETCHING_LOGIN:
            return state.set('isFetchingLogin', true);

        case types.STOP_FETCHING_LOGIN:
            return state.set('isFetchingLogin', false);

        case types.START_FETCHING_LOGIN_ORG:
            return state.set('isFetchingLoginOrg', true);

        case types.STOP_FETCHING_LOGIN_ORG:
            return state.set('isFetchingLoginOrg', false);

        case types.START_FETCHING_LOGOUT:
            return state.set('isFetchingLogout', true);

        case types.STOP_FETCHING_LOGOUT:
            return state.set('isFetchingLogout', false);

        default:
            return state;
    }
};
