import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = {
    errorMessage: {
    }
};

const ResetPasswordValidationError = (props) => {

    const { classes, errors } = props;

    return (
        <div className={classes.errorMessage}>
            {errors.map( (error, index) => {
                return <p key={index} className={classes.errorMessage}>{error}</p>;
            })}
        </div>
    );
};

export default withStyles(styles)(ResetPasswordValidationError);

