import React from 'react';
import { authActions } from '../../bus/auth/actions';
import UsernameForm from './Forms/UsernameForm';
import LoginForm from './Forms/LoginForm';
import OrganizationForm from './Forms/OrganizationForm';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import cookie from 'js-cookie';
import { addCountlyUserDetails } from '../../helpers';
import { uiActions } from '../../bus/ui/actions';
import { api } from '../../config/api';
import {
    errors,
    SOURCE_MICROSITES,
    TOKEN_PREFIX,
} from '../../config/constants';
import { toastr } from 'react-redux-toastr';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimation: 'cardHidden', // we use this to make the card to appear after the page has been rendered
            username: '',
            continueUrl: null,
            source: null,
            selectedOrgId: null,
        };
        this.handleSubmitCheckUser = this.handleSubmitCheckUser.bind(this);
        this.handleSubmitLogin = this.handleSubmitLogin.bind(this);
        this.handleSubmitOrg = this.handleSubmitOrg.bind(this);
        this.goBackToUsername = this.goBackToUsername.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    init() {
        const { dispatch } = this.props;
        const query = new URLSearchParams(this.props.location.search);
        let authToken = cookie.get('auth_token');
        let continueUrl = query.get('continue');
        let source = query.get('source');
        if (
            !continueUrl ||
            continueUrl === 'undefined' ||
            continueUrl === 'null'
        ) {
            continueUrl = null;
        }
        if (!source || source === 'undefined' || source === 'null') {
            source = null;
            this.setState({ hasSource: false });
        }

        /** Check for auth_token from URL when redirected back from Cognito */
        const authTokenFromCognito = query.get('rm');
        if (authTokenFromCognito) {
            cookie.set('auth_token', authTokenFromCognito);
            authToken = authTokenFromCognito;
        }

        if (authToken) {
            /** skip login, authenticate and go to Step-3 */
            dispatch(
                authActions.authenticateAsync({
                    continueUrl,
                    authToken,
                    source: source || 'auth',
                })
            );
        } else {
            dispatch(authActions.setLoginStep(1));
        }
        this.setState({ continueUrl: continueUrl });
        this.setState({ source: source });
    }

    componentDidMount() {
        // *** we add a hidden class to the card and after 500 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimation: '' });
            }.bind(this),
            500
        );

        this.init();
    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    goBackToUsername() {
        const { dispatch } = this.props;
        dispatch(authActions.setLoginStep(1));
    }

    handleSubmitCheckUser = (formData) => {
        const { dispatch } = this.props;
        const { continueUrl, source } = this.state;
        const { username } = formData;
        this.setState({ username: username });
        dispatch(authActions.checkUserAsync({ username, continueUrl, source }));
    };

    handleSubmitLogin = (formData) => {
        const { dispatch } = this.props;
        const { username } = this.state;
        const { password } = formData;
        const { continueUrl } = this.state;
        const { source } = this.state;

        const login = async () => {
            try {
                await dispatch(uiActions.startFetchingLogin());

                const {
                    data: { data },
                } = await api.auth.login({ username, password, source });
                await dispatch(authActions.setUserData(data.user));

                if (data && data['auth_token']) {
                    cookie.set('auth_token', data['auth_token']);
                } else {
                    throw new Error(errors.noToken);
                }

                /** Go To Microsites */
                if (continueUrl && source && source === SOURCE_MICROSITES) {
                    return (window.location =
                        continueUrl + TOKEN_PREFIX + data['auth_token']);
                }
                const payload = {
                    authToken: data['auth_token'],
                    source,
                };
                await dispatch(authActions.getOrganizationsAsync(payload));
            } catch (error) {
                console.error('Login:', error.message);
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.error &&
                    error.response.data.error.code
                ) {
                    toastr.error('Error', error.response.data.error.message, {
                        position: 'top-center',
                    });
                } else {
                    toastr.error('Error', errors.other, {
                        position: 'top-center',
                    });
                }
            } finally {
                await dispatch(uiActions.stopFetchingLogin());
            }
        };

        login();
    };

    handleSubmitOrg(selectedOrg, selectedSource) {
        const { dispatch, user } = this.props;
        const selectedOrgId = selectedOrg.value;
        const { continueUrl } = this.state;
        const source = selectedSource ? selectedSource : this.state.source;

        addCountlyUserDetails(user, selectedOrg);
        dispatch(
            authActions.loginOrgAsync({ selectedOrgId, continueUrl, source })
        );
    }

    handleLogout() {
        const rmApp = true;
        const { dispatch } = this.props;
        const query = new URLSearchParams(this.props.location.search);
        const continueUrl = query.get('continue');
        const source = query.get('source');
        dispatch(authActions.logoutAsync({ continueUrl, source, rmApp }));
    }

    render() {
        const {
            classes,
            loginStep,
            isFetching,
            isFetchingCheckUser,
            isFetchingLogin,
            isFetchingGetOrg,
            isFetchingLoginOrg,
            isFetchingLogout,
            organizations,
            user,
        } = this.props;
        const { username } = this.state;

        const isAdmin =
            (user &&
                user['global_roles'].some(
                    (role) => role === 'ROLE_ADMIN' || role === 'ROLE_SUPPORT'
                )) ||
            null;

        if (!loginStep || isFetchingLogout) {
            return (
                <Grid container justify='center'>
                    <CircularProgress />s
                </Grid>
            );
        }

        switch (loginStep) {
            case 1:
                return (
                    <UsernameForm
                        classes={classes}
                        handleSubmitCheckUser={this.handleSubmitCheckUser}
                        username={username}
                        isFetching={isFetchingCheckUser}
                    />
                );
            case 2:
                return (
                    <LoginForm
                        classes={classes}
                        handleSubmitLogin={this.handleSubmitLogin}
                        goBackToUsername={this.goBackToUsername}
                        username={username}
                        isFetching={isFetchingLogin || isFetchingGetOrg}
                    />
                );
            case 3:
                return (
                    <OrganizationForm
                        classes={classes}
                        handleSubmitOrg={this.handleSubmitOrg}
                        goBackToUsername={this.goBackToUsername}
                        isFetching={isFetchingLoginOrg}
                        organizations={organizations}
                        handleLogout={this.handleLogout}
                        isAdmin={isAdmin}
                        source={this.state.source}
                        showAppSelector={
                            !(this.state.continueUrl || this.state.source)
                        }
                    />
                );
            default:
                return (
                    <UsernameForm
                        classes={classes}
                        handleSubmitCheckUser={this.handleSubmitCheckUser}
                        username={username}
                        isFetching={isFetching}
                    />
                );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.ui.get('isFetching'),
        isFetchingCheckUser: state.ui.get('isFetchingCheckUser'),
        isFetchingGetOrg: state.ui.get('isFetchingGetOrg'),
        isFetchingLogin: state.ui.get('isFetchingLogin'),
        isFetchingLoginOrg: state.ui.get('isFetchingLoginOrg'),
        isFetchingLogout: state.ui.get('isFetchingLogout'),
        user: state.auth.get('user'),
        loginStep: state.auth.get('loginStep'),
        organizations: state.auth.get('organizations') || [],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

const connectedLoginPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPage);
export default withStyles(loginPageStyle)(connectedLoginPage);
