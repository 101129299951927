import * as Yup from 'yup';

export const username = {
    // shape: { username: '' },
    schema: Yup.object({
        username: Yup.string('Enter your username')
            .required('Enter your username'),
    }),
};

export const password = {
    shape: {
        password: ''
    },
    schema: Yup.object({
        password: Yup.string('Enter the password')
            .required('Please enter your password'),
    }),
};

export const resetPassword = {
    shape: {
        newPassword: '',
        confirmPassword: '',
    },
    schema: Yup.object({
        newPassword: Yup.string('Enter the password')
            .min(5, 'Password must contain at least 5 characters')
            .required('Enter your password'),
        confirmPassword: Yup.string('Confirm your password')
            // .min(5, 'Password must contain at least 5 characters')
            .required('Confirm your password')
            .oneOf([Yup.ref('newPassword')], 'Password does not match')
    }),
};
