import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import cookie from 'js-cookie';
import PropTypes from 'prop-types';
import CustomReactSelect from '../../../components/CustomReactSelect/CustomReactSelect.jsx';

export default class OrganizationForm extends React.Component {
    state = {
        selectedOrg: null,
        authToken: '',
        userFullName: null,
        source: null,
    };

    componentDidMount() {
        /** If only one organizations, skip the Step 3*/
        const { isAdmin, organizations } = this.props;
        const numberOfOrganizations = organizations.length;
        this.setState({ source: this.props.source });
        if (numberOfOrganizations === 1 && !isAdmin) {
            const SelectedOrg = {
                label: organizations[0].name,
                value: organizations[0].id,
            };
            this.props.handleSubmitOrg(SelectedOrg);
        }

        this.setState({ authToken: cookie.get('auth_token') });
        this.setState({ userFullName: cookie.get('ag_user') });
    }

    handleSelectOrganization = (org) => {
        this.setState({ selectedOrg: org });
    };

    handleSelectApp = (source) => this.setState({ source });

    handleOnSubmit = (e) => {
        e.preventDefault();
        const source = this.props.showAppSelector
            ? this.state.source.value
            : null;
        if (!this.props.isFetching && this.state.selectedOrg) {
            this.props.handleSubmitOrg(this.state.selectedOrg, source);
        }
    };

    render() {
        const { classes, isFetching, organizations, handleLogout, isAdmin } =
            this.props;
        const { selectedOrg, source } = this.state;

        if (isFetching) {
            return (
                <Grid container justify='center'>
                    <CircularProgress />
                </Grid>
            );
        }

        let organizationsJSX = organizations.map((org) => ({
            label: org.name,
            value: org.id,
        }));

        const appNamesList = [
            { label: 'MyLive', value: 'remote' },
            { label: 'Mobilizer', value: 'spa' },
        ];

        return (
            <form onSubmit={(e) => this.handleOnSubmit(e)}>
                <Card login>
                    {' '}
                    {/*className={classes[this.state.cardAnimation]}*/}
                    <CardHeader
                        className={`${classes.cardHeader} ${classes.textCenter}`}
                        color='info'
                    >
                        <h4 className={classes.cardTitle}>
                            Select Organization
                        </h4>
                    </CardHeader>
                    <CardBody>
                        {!isFetching && organizations.length === 0 ? (
                            <CardBody>
                                <Typography variant='body1' align='center'>
                                    No organization found
                                </Typography>
                            </CardBody>
                        ) : (
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <CustomReactSelect
                                    options={organizationsJSX}
                                    onChange={this.handleSelectOrganization}
                                    value={this.state.selectedOrg}
                                    placeholder='Organization'
                                />
                            </FormControl>
                        )}
                        {this.props.showAppSelector ? (
                            <FormControl
                                fullWidth
                                className={classes.selectFormControl}
                                margin='dense'
                            >
                                <CustomReactSelect
                                    options={appNamesList}
                                    onChange={this.handleSelectApp}
                                    value={this.state.source}
                                    placeholder='Select an app'
                                />
                            </FormControl>
                        ) : null}
                        {isAdmin && (
                            <Button
                                href={
                                    process.env
                                        .REACT_APP_ADMIN_TOOL_CLIENT_URL +
                                    '/auth/___' +
                                    this.state.authToken
                                }
                                disabled={isFetching}
                                color='primary'
                                size='sm'
                                simple
                                block
                            >
                                Manage Organizations
                            </Button>
                        )}
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button
                            color='primary'
                            simple
                            size='lg'
                            block
                            onClick={handleLogout}
                            disabled={isFetching}
                        >
                            Logout
                        </Button>
                        <Button
                            color='primary'
                            simple
                            size='lg'
                            block
                            type={'submit'}
                            disabled={
                                isFetching ||
                                !selectedOrg ||
                                (this.props.showAppSelector && !source)
                            }
                        >
                            Next
                        </Button>
                    </CardFooter>
                    {isFetching && <LinearProgress />}
                </Card>
            </form>
        );
    }
}

OrganizationForm.propTypes = {
    showAppSelector: PropTypes.bool.isRequired,
};
