import { apply, put } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { authActions } from 'bus/auth/actions';
import cookie from 'js-cookie';
import {
    SOURCE_REMOTE,
    SOURCE_MOBILIZER,
    TOKEN_PREFIX,
} from '../../../../config/constants';
import { displayError } from '../../../../helpers';

/**
 *
 * @param {string} source
 * @returns {Boolean} return true if source equal to remote soruce
 */
const isRemoteSource = (source) => source === SOURCE_REMOTE;

/**
 *
 * @param {string} source
 * @returns {Boolean} return true if source equal to mobilizer source
 */
const isMobilizerSource = (source) => source === SOURCE_MOBILIZER;

export function* loginOrgWorker({ payload }) {
    try {
        const { selectedOrgId, continueUrl, source } = payload;
        yield put(uiActions.startFetchingLoginOrg());

        const authToken = yield apply(cookie, cookie.get, ['auth_token']);
        const {
            data: { data },
        } = yield apply(api, api.auth.loginOrg, [
            authToken,
            selectedOrgId,
            source,
        ]);
        /** Redirect to intended URL */
        if (continueUrl && source) {
            redirectWithUrlAndSource(
                continueUrl,
                source,
                selectedOrgId,
                data['mobilizer_token'],
                TOKEN_PREFIX,
                isRemoteSource,
                isMobilizerSource
            );
            return null;
        } else if (!continueUrl && source) {
            redirectWithSource(
                source,
                selectedOrgId,
                data['mobilizer_token'],
                TOKEN_PREFIX,
                isRemoteSource,
                isMobilizerSource
            );
            return null;
        } else if (continueUrl && !source) {
            window.location =
                continueUrl +
                TOKEN_PREFIX +
                data['mobilizer_token'] +
                ',' +
                selectedOrgId;
            return null;
        } else {
            yield put(authActions.setLoginStep(3));
            yield put(uiActions.stopFetchingLoginOrg());
        }
    } catch (error) {
        yield put(uiActions.stopFetchingLoginOrg());
        displayError('loginOrgWorker', error);
    }
}

/**
 * Redirect the page to url when both source and url are exists
 * @param {string} url
 * @param {string} source
 * @param {string} selectedOrgId
 * @param {string} mobilizerToken
 * @param {string} tokenPrefix
 * @param {Function} isRemoteSourceFn function to check is remote source
 * @param {Function} isMobilizerSourceFn
 */
function redirectWithUrlAndSource(
    url,
    source,
    selectedOrgId,
    mobilizerToken,
    tokenPrefix,
    isRemoteSourceFn,
    isMobilizerSourceFn
) {
    if (isRemoteSourceFn(source)) {
        window.location = url + '?mobilizer-token=' + mobilizerToken;
    } else if (isMobilizerSourceFn(source)) {
        window.location =
            url +
            '/#/content' +
            tokenPrefix +
            mobilizerToken +
            ',' +
            selectedOrgId;
    } else {
        window.location =
            url + tokenPrefix + mobilizerToken + ',' + selectedOrgId;
    }
}

/**
 * Redirect the page base on source
 * @param {string} url
 * @param {string} source
 * @param {string} selectedOrgId
 * @param {string} mobilizerToken
 * @param {string} tokenPrefix
 * @param {Function} isRemoteSourceFn function to check is remote source
 * @param {Function} isMobilizerSourceFn
 */
function redirectWithSource(
    source,
    selectedOrgId,
    mobilizerToken,
    tokenPrefix,
    isRemoteSourceFn,
    isMobilizerSourceFn
) {
    if (isRemoteSourceFn(source)) {
        window.location =
            process.env.REACT_APP_MYLIVE_URL +
            '?mobilizer-token=' +
            mobilizerToken;
    } else if (isMobilizerSourceFn(source)) {
        window.location =
            process.env.REACT_APP_MOBILIZER_URL +
            '/#/content' +
            tokenPrefix +
            mobilizerToken +
            ',' +
            selectedOrgId;
    } else {
        window.location =
            process.env.REACT_APP_MOBILIZER_URL +
            '/#/content' +
            tokenPrefix +
            mobilizerToken +
            ',' +
            selectedOrgId;
    }
}
