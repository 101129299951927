export const api = `${process.env.REACT_APP_RM_API}/api`;

// *** AUTH ****
export const AUTHENTICATE_API = `${api}/auth/me`;
export const CHECK_USERNAME_API = `${api}/auth/sso`;
export const LOGIN_API = `${api}/auth/login`;
export const GET_ORGANIZATIONS_API = `${api}/auth/organizations`;
export const FORGOT_PASS_API = `${api}/auth/password/forgot`;
export const RESET_PASS_API = `${api}/auth/password/reset`;
export const GET_PASSWORD_POLICY = `${api}/auth/password/policy`;
export const LOGOUT_API = `${api}/auth/logout`;

// *** Other ****
export const SOURCE_MICROSITES = 'microsites';
export const SOURCE_REMOTE = 'remote';
export const SOURCE_MOBILIZER = 'spa';
export const TOKEN_PREFIX = '#___';

/*** Error Messages ***/
export const errors = {
    other: 'Something went wrong',
    noToken: 'Token not found',
};
