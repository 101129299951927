import { takeEvery, all, call } from 'redux-saga/effects';
import types from '../types';
import { authenticateWorker, checkUserWorker, resetPassWorker, forgotPassWorker, getPasswordPolicyWorker, loginOrgWorker, logoutWorker, getOrganizationsWorker } from './workers';

function* checkUserWatcher () {
    yield takeEvery(types.CHECK_USER_ASYNC, checkUserWorker);
}
function* loginOrgWatcher () {
    yield takeEvery(types.LOGIN_ORG_ASYNC, loginOrgWorker);
}
function* getOrganizationsWatcher () {
    yield takeEvery(types.GET_ORGANIZATIONS_ASYNC, getOrganizationsWorker);
}
function* authenticateWatcher () {
    yield takeEvery(types.AUTHENTICATE_ASYNC, authenticateWorker);
}
function* forgotPassWatcher () {
    yield takeEvery(types.FORGOT_PASSWORD, forgotPassWorker);
}
function* resetPassWatcher () {
    yield takeEvery(types.RESET_PASSWORD_ASYNC, resetPassWorker);
}
function* getPasswordPolicyWatcher () {
    yield takeEvery(types.GET_PASSWORD_POLICY_ASYNC, getPasswordPolicyWorker);
}
function* logoutWatcher () {
    yield takeEvery(types.LOGOUT_ASYNC, logoutWorker);
}

export function* authWatchers () {
    yield all(
        [
            call(checkUserWatcher),
            call(getOrganizationsWatcher),
            call(loginOrgWatcher),
            call(authenticateWatcher),
            call(forgotPassWatcher),
            call(resetPassWatcher),
            call(getPasswordPolicyWatcher),
            call(logoutWatcher),
        ]
    );
}
