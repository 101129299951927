export default Object.freeze({
    // ASYNC
    AUTHENTICATE_ASYNC: 'AUTHENTICATE_ASYNC',
    CHECK_USER_ASYNC: 'CHECK_USER_ASYNC',
    GET_ORGANIZATIONS_ASYNC: 'GET_ORGANIZATIONS_ASYNC',
    GET_PASSWORD_POLICY_ASYNC: 'GET_PASSWORD_POLICY_ASYNC',
    RESET_PASSWORD_ASYNC: 'RESET_PASSWORD_ASYNC',
    LOGIN_ORG_ASYNC: 'LOGIN_ORG_ASYNC',
    LOGOUT_ASYNC: 'LOGOUT_ASYNC',

    // SYNC
    SET_LOGIN_STEP: 'SET_LOGIN_STEP',
    SET_USER_DATA: 'SET_USER_DATA',
    FILL_ORGANIZATIONS: 'FILL_ORGANIZATIONS',
    FORGOT_PASSWORD: 'FORGOT_PASSWORD',
    GET_PASSWORD_POLICY_FAILED: 'GET_PASSWORD_POLICY_FAILED',
    FILL_PASSWORD_POLICY: 'FILL_PASSWORD_POLICY',
    SET_IS_EMAIL_SENT: 'SET_IS_EMAIL_SENT',
    SET_IS_RESET_PASS_SUBMITTED: 'SET_IS_RESET_PASS_SUBMITTED',
});
