import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from './init/store';
import history from './history';
import App from './App';
import { Router } from 'react-router';
import ReduxToastr from 'react-redux-toastr';
import './index.css';
import './assets/scss/material-dashboard-pro-react.css?v=1.3.0';
import 'typeface-roboto';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './assets/scss/chartist.min.css';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

if (process.env.NODE_ENV === 'production') {
    datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        datacenter: 'us',
        forwardErrorsToLogs: true,
        sampleRate: 100
    });

    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APP_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        datacenter: 'us',
        resourceSampleRate: 100,
        sampleRate: 100
    });
}

const target = document.querySelector('#root');

render(
    <Provider store={store}>
        <div>
            <Router history={history}>
                <App />
            </Router>
            <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position='top-right'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                // progressBar
                closeOnToastrClick
            />
        </div>
    </Provider>,
    target
);
