import { toastr } from 'react-redux-toastr';
import { errors } from '../config/constants';

export function buildUrl(url, obj) {
    let str = [];
    // eslint-disable-next-line no-unused-vars
    for (let key in obj) {
        // eslint-disable-next-line  no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
            str.push(
                encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
            );
        }
    }

    return url + '?' + str.join('&');
}

export function addCountlyUserDetails(userData, selectedOrg) {
    const getUsersFullName = user => {
        const firstName = user['first_name'] ? user['first_name'] + ' ' : '';
        const middleName = user['middle_name'] ? user['middle_name'] + ' ' : '';
        const lastName = user['last_name'] ? user['last_name'] : '';
        return firstName + middleName + lastName;
    };

    window.Countly.q.push([
        'user_details',
        {
            name: getUsersFullName(userData),
            organization: selectedOrg.label,
            custom: {
                OrganizationId: selectedOrg.value
            }
        }
    ]);
}

export function displayError(msg, error) {
    console.error(msg, error);
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
    ) {
        toastr.error('Error', error.response.data.error.message, {
            position: 'top-center'
        });
    } else {
        toastr.error('Error', errors.other, { position: 'top-center' });
    }
}
