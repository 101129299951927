import React, {Component} from 'react';
import Collapse from '@material-ui/core/Collapse';
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import PropTypes from 'prop-types';

const styles = {
    listItem: {
        fontSize: '0.7em',
        lineHeight: '0.8em',
    },
    showHideButton: {
        cursor: 'pointer',
        fontSize: '0.8em',
        textAlign: 'center',
    }
};

class ShowPasswordRules extends Component {

    constructor() {
        super();
        this.state = {
            showText: false
        };
    }

    render() {

        const {passwordPolicy: { descriptions = {}}, classes} = this.props;
        const {showText} = this.state;

        return <>
            <p
                className={classes.showHideButton}
                onClick={() => this.setState({showText: !this.state.showText})}>
                {showText ? 'Hide password rules' : 'Show password rules'}
            </p>

            <Collapse in={this.state.showText}>
                <List>
                    {Object.keys(descriptions).map((key, index) => {
                        if (descriptions[key]) {
                            return <p key={index} className={classes.listItem}>
                                {descriptions[key]}
                            </p>;
                        } else return null;
                    })}
                </List>
            </Collapse>
        </>;
    }
}

ShowPasswordRules.propTypes = {
    classes: PropTypes.object.isRequired,
    passwordPolicy: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowPasswordRules);
