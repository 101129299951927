import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import authStyle from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx';

import bgImage from 'assets/img/auth-bg.jpg';
import authRoutes from 'routes/authRoutes';
import AuthFooter from '../components/Footer/AuthFooter';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from '../components/Grid/GridContainer';

class Auth extends React.Component {
    componentDidMount() {
        document.body.style.overflow = 'unset';
    }
    render() {
        const { classes } = this.props;

        const brand = (
            <div className={classes.authLogo}>
                {/*<img src={logo} alt='logo' className={classes.img} />*/}
            </div>
        );

        return (
            <div className={classes.wrapper} ref='wrapper'>
                <div
                    className={classes.fullPage}
                    style={{ backgroundImage: 'url(' + bgImage + ')' }}
                >
                    <div className={classes.container}>
                        <GridContainer justify='center'>{brand}</GridContainer>
                        <GridContainer justify='center'>
                            <GridItem xs={12} sm={'auto'} md={5} lg={4} xl={4}>
                                {' '}
                                {/* xs={12} sm={6} md={4} */}
                                <Switch>
                                    {authRoutes.map((prop, key) => {
                                        if (prop.collapse) {
                                            return null;
                                        }
                                        if (prop.redirect) {
                                            return (
                                                <Redirect
                                                    from={prop.path}
                                                    to={prop.pathTo}
                                                    key={key}
                                                />
                                            );
                                        }
                                        return (
                                            <Route
                                                path={prop.path}
                                                component={prop.component}
                                                key={key}
                                            />
                                        );
                                    })}
                                </Switch>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <AuthFooter white />
                </div>
            </div>
        );
    }
}

Auth.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(authStyle)(Auth);
