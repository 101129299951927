// Types
import { types } from './types';

export const uiActions = {
    startFetching: () => {
        return {
            type: types.START_FETCHING,
        };
    },
    stopFetching: () => {
        return {
            type: types.STOP_FETCHING,
        };
    },
    startFetchingCheckUser: () => {
        return {
            type: types.START_FETCHING_CHECK_USER,
        };
    },
    stopFetchingCheckUser: () => {
        return {
            type: types.STOP_FETCHING_CHECK_USER,
        };
    },
    startFetchingGetOrg: () => {
        return {
            type: types.START_FETCHING_GET_ORG,
        };
    },
    stopFetchingGetOrg: () => {
        return {
            type: types.STOP_FETCHING_GET_ORG,
        };
    },
    startFetchingLogin: () => {
        return {
            type: types.START_FETCHING_LOGIN,
        };
    },
    stopFetchingLogin: () => {
        return {
            type: types.STOP_FETCHING_LOGIN,
        };
    },
    startFetchingLoginOrg: () => {
        return {
            type: types.START_FETCHING_LOGIN_ORG,
        };
    },
    stopFetchingLoginOrg: () => {
        return {
            type: types.STOP_FETCHING_LOGIN_ORG,
        };
    },
    startFetchingLogout: () => {
        return {
            type: types.START_FETCHING_LOGOUT,
        };
    },
    stopFetchingLogout: () => {
        return {
            type: types.STOP_FETCHING_LOGOUT,
        };
    },
};
