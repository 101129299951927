import types from './types';
import {errors} from '../../config/constants';


export const authActions = Object.freeze({

    // *** Async ***

    authenticateAsync: payload => ({
        type: types.AUTHENTICATE_ASYNC,
        payload
    }),

    checkUserAsync: payload => ({
        type: types.CHECK_USER_ASYNC,
        payload
    }),

    loginOrgAsync: payload => ({
        type: types.LOGIN_ORG_ASYNC,
        payload
    }),

    getOrganizationsAsync: payload => ({
        type: types.GET_ORGANIZATIONS_ASYNC,
        payload: payload
    }),

    logoutAsync: payload => ({
        type: types.LOGOUT_ASYNC,
        payload
    }),

    getPasswordPolicyAsync: (resetToken) => ({
        type: types.GET_PASSWORD_POLICY_ASYNC,
        payload: resetToken
    }),

    resetPassAsync: (username) => ({
        type: types.RESET_PASSWORD_ASYNC,
        payload: username
    }),

    // *** Sync ***

    setOrganizations: organizations => ({
        type: types.FILL_ORGANIZATIONS,
        payload: organizations,
    }),

    setLoginStep: (loginStep) => ({
        type: types.SET_LOGIN_STEP,
        payload: loginStep
    }),

    setUserData: payload => ({
        type: types.SET_USER_DATA,
        payload
    }),

    forgotPass: (username) => ({
        type: types.FORGOT_PASSWORD,
        payload: username
    }),

    fillPasswordPolicy: (passwordPolicy) => ({
        type: types.FILL_PASSWORD_POLICY,
        payload: passwordPolicy
    }),

    getPasswordPolicyFailed: (error) => ({
        type: types.GET_PASSWORD_POLICY_FAILED,
        payload: error.response.data.error.message || errors.other
    }),

    setIsEmailSent: (isEmailSent) => ({
        type: types.SET_IS_EMAIL_SENT,
        payload: isEmailSent
    }),

    setIsResetPassSubmitted: (isResetPassSubmitted) => ({
        type: types.SET_IS_RESET_PASS_SUBMITTED,
        payload: isResetPassSubmitted
    })
});
