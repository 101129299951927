import React from 'react';
import {connect} from 'react-redux';
import {authActions} from '../../bus/auth/actions';

class LogoutPage extends React.Component {

    componentDidMount() {
        const {dispatch} = this.props;
        const query = new URLSearchParams(this.props.location.search);
        const continueUrl = query.get('continue');
        const source = query.get('source');
        const rmApp = query.get('rm-app'); // this flag is used to check if logout needs to happen from Cognito first
        dispatch(authActions.logoutAsync({continueUrl, source, rmApp}));
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

const connectedLogoutPage = connect(null, mapDispatchToProps)(LogoutPage);
export default connectedLogoutPage;
