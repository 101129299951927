import { put, apply } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import history from '../../../../history';
import { book } from '../../../../config/book';
import cookie from 'js-cookie';
import { buildUrl, displayError } from '../../../../helpers';

export function* logoutWorker({ payload }) {
    const authToken = yield apply(cookie, cookie.get, ['auth_token']);

    try {
        yield put(uiActions.startFetchingLogout());

        const { rmApp } = payload;

        if (rmApp && authToken) {
            const { data } = yield apply(api, api.auth.getCognitoLogoutUrl, [
                authToken,
            ]);
            yield apply(cookie, cookie.remove, ['auth_token']);
            yield apply(cookie, cookie.remove, ['ag_user']);
            if (data && data['logout_url']) {
                window.location.href = data['logout_url'];
                return null;
            }
        }

        if (authToken) {
            const { data } = yield apply(api, api.auth.logout, [authToken]);
            yield apply(cookie, cookie.remove, ['auth_token']);
            yield apply(cookie, cookie.remove, ['ag_user']);

            /** redirects to SSO logout `redirect_url` if it is in the response */
            if (data && data['redirect_url']) {
                yield (window.location.href = data['redirect_url']);
                return null;
            }
        }

        yield put(uiActions.stopFetchingLogout());

        const params = {
            continue: payload.continueUrl,
            source: payload.source,
            'rm-app': payload.rmApp,
        };
        const loginURL = buildUrl(book.login, params);
        yield history.push(loginURL);
        yield put(authActions.setLoginStep(1));
    } catch (error) {
        displayError('logoutWorker', error);

        yield apply(cookie, cookie.remove, ['auth_token']);
        yield apply(cookie, cookie.remove, ['ag_user']);
        yield put(uiActions.stopFetchingLogout());
        yield history.push(book.login);
        yield put(authActions.setLoginStep(1));
    }
}
