import { apply, call, put } from 'redux-saga/effects';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { toastr } from 'react-redux-toastr';
import { authActions } from '../../actions';
import React from 'react';
import ResetPasswordValidationError from '../../../../components/ResetPasswordValidationError/ResetPasswordValidationError';
import { errors } from '../../../../config/constants';

export function* resetPassWorker({ payload }) {
    try {
        let { resetToken, newPassword } = payload;

        yield put(uiActions.startFetching());

        yield apply(api, api.auth.resetPass, [resetToken, newPassword]);

        yield put(authActions.setIsResetPassSubmitted(true));
    } catch (error) {
        console.error('resetPassWorker', error);
        if (
            error &&
            error.response &&
            error.response.data &&
            error.response.status === 422
        ) {
            const toastrConfirmOptions = {
                component: () => (
                    <ResetPasswordValidationError
                        errors={error.response.data.error.errors.password}
                    />
                ),
                position: 'top-center'
            };
            yield call(toastr.error, 'Error', toastrConfirmOptions);
        } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.errors
        ) {
            yield call(
                toastr.error,
                'Error',
                error.response.data.error.errors[0],
                { position: 'top-center' }
            );
        } else {
            yield call(toastr.error, 'Error', errors.other, {
                position: 'top-center'
            });
        }
    } finally {
        yield put(uiActions.stopFetching());
    }
}
