import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {Route, Switch, withRouter} from 'react-router';
import './App.scss';
import 'typeface-roboto';
import Auth from './layouts/Auth';
import {connect} from 'react-redux';
import {book} from './config/book';

export class App extends Component {

    render() {

        return (
            <Switch>
                <Route path={'/'} component={Auth} key={1}/>
                <Route path={book.logout} component={Auth} key={2}/>
                <Route path={book.logoutOrg} component={Auth} key={3}/>
                <Redirect to={book.login}/>
            </Switch>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

const connectedApp = connect(null, mapDispatchToProps)(App);
export default withRouter(connectedApp);
