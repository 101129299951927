import { combineReducers } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import ui from 'bus/ui/reducer';
import auth from 'bus/auth/reducer';

export const rootReducer = combineReducers({
    toastr: toastrReducer,
    ui,
    auth,
});
