import { apply, put } from 'redux-saga/effects';
import { authActions } from '../../actions';
import { api} from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import history from '../../../../history';
import { book } from '../../../../config/book';
import { SOURCE_MICROSITES, TOKEN_PREFIX } from '../../../../config/constants';
import { displayError } from '../../../../helpers';

export function* authenticateWorker({payload}) {

    const {authToken, continueUrl, source} = payload;

    try {
        yield put(uiActions.startFetching());

        const {data: {data: user}} = yield apply(api, api.auth.authenticate, [authToken]);
        yield put(authActions.setUserData(user));

        /** Go To Microsites */
        if (continueUrl && source && (source === SOURCE_MICROSITES)) {
            window.location = continueUrl + TOKEN_PREFIX + authToken;
            return null;
        }

        yield put(authActions.getOrganizationsAsync({authToken, source}));

    } catch (error) {
        displayError('authenticateWorker', error);
        yield put(authActions.logoutAsync({rmApp: false}));
        history.push(book.login);
    } finally {
        yield put(uiActions.stopFetching());
    }
}
