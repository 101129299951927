import { apply, put } from 'redux-saga/effects';
import { authActions } from 'bus/auth/actions';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { buildUrl, displayError } from '../../../../helpers';

export function* checkUserWorker ({ payload }) {

    try {
        yield put(uiActions.startFetchingCheckUser());

        let { username, continueUrl, source } = payload;

        const { data } = yield apply(api, api.auth.checkUsername, [username]);

        if ( data.data.sso === null) {
            yield put(authActions.setLoginStep(2));
        } else {

            let state = {
                schema: '1.0',
                pool_id: data.data.sso['pool_id'],
                redirect_uri: data.data.sso['redirect_uri'],
                login_url: process.env.REACT_APP_LOGIN_APP + '/login',
                continue: continueUrl || null,
                source: source,
            };

            let encodeState = encodeURIComponent(JSON.stringify(state));

            let params = {
                'response_type': 'code',
                'client_id':      data.data.sso['client_id'],
                'redirect_uri':   data.data.sso['redirect_uri'],
                'state': encodeState,
            };

            let ssoLink = buildUrl(data.data.sso['sso_base_url'], params);

            window.open(ssoLink, '_self');
        }

    } catch (error) {
        displayError('checkUserWorker', error);
    }
    finally {
        yield put(uiActions.stopFetchingCheckUser());
    }
}
