import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import footerStyle from 'assets/jss/material-dashboard-pro-react/components/footerStyle';
import agnitioLogo from 'assets/img/agnitio-logo.svg';

function AuthFooter({ ...props }) {
    const { classes, fluid, white } = props;
    const container = cx({
        [classes.container]: !fluid,
        [classes.containerFluid]: fluid,
        [classes.footerTextColor]: white
    });

    return (
        <footer className={classes.footer}>
            <div className={container}>
                <p className={classes.bottomBranding}>
                    &copy; {1900 + new Date().getYear()}{' '}
                    <a href='https://www.agnitio.com' className={classes.footerTextColor}>
                      Technology by Agnitio - A Bigtincan Company
                        <br />
                        <img src={agnitioLogo} alt='logo' className={classes.logo} />
                    </a>
                </p>
            </div>
        </footer>
    );
}

AuthFooter.propTypes = {
    classes: PropTypes.object.isRequired,
    fluid: PropTypes.bool,
    white: PropTypes.bool,
    rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(AuthFooter);
