import React from 'react';
import { Field, Form, Formik } from 'formik';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import LockOpen from '@material-ui/icons/LockOpen';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '../../../components/CustomButtons/Button';
import CustomInputFormik from '../../../components/CustomInputFormik/CustomInputFormik';
import { resetPassword } from '../../../bus/forms/shapes';
import * as Yup from 'yup';
import ShowPasswordRules from '../../../components/ShowPasswordRules/ShowPasswordRules';
import ResetPasswordValidationError from '../../../components/ResetPasswordValidationError/ResetPasswordValidationError';

export default class ResetForm extends React.Component {
    render() {
        const {
            classes,
            handleSubmit,
            isFetching,
            passwordPolicy,
            resetPasswordErrors
        } = this.props;
        const { rules = {} } = passwordPolicy;

        const validationSchema = Yup.object({
            newPassword: Yup.string('Enter the password')
                .min(
                    rules['min_length'],
                    `Password must contain at least ${
                        rules['min_length']
                    } characters`
                )
                .max(
                    rules['max_length'] || 5,
                    `Password must contain maximum of ${rules['max_length'] ||
                        5} characters`
                )
                .required('Enter your password'),
            confirmPassword: Yup.string('Confirm your password')
                .required('Confirm your password')
                .oneOf([Yup.ref('newPassword')], 'Password does not match')
        });

        return (
            <Formik
                initialValues={resetPassword.shape}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                render={props => {
                    return (
                        <Form>
                            <Card login>
                                <CardHeader
                                    className={`${classes.cardHeader} ${
                                        classes.textCenter
                                    }`}
                                    color='info'
                                >
                                    <h4 className={classes.cardTitle}>
                                        Set New Password
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Field
                                        name='newPassword'
                                        labelText='New password'
                                        formControlProps={{ fullWidth: true }}
                                        disabled={isFetching}
                                        inputProps={{
                                            type: 'password',
                                            endAdornment: (
                                                <InputAdornment position='start'>
                                                    <LockOpen
                                                        className={
                                                            classes.inputAdornmentIcon
                                                        }
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                        component={CustomInputFormik}
                                    />
                                    <Field
                                        name='confirmPassword'
                                        labelText='Confirm Password'
                                        formControlProps={{ fullWidth: true }}
                                        disabled={isFetching}
                                        inputProps={{
                                            type: 'password',
                                            endAdornment: (
                                                <InputAdornment position='start'>
                                                    <LockOpen
                                                        className={
                                                            classes.inputAdornmentIcon
                                                        }
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                        component={CustomInputFormik}
                                    />
                                    {resetPasswordErrors ? (
                                        <ResetPasswordValidationError
                                            errors={resetPasswordErrors}
                                        />
                                    ) : null}

                                    <ShowPasswordRules
                                        passwordPolicy={passwordPolicy}
                                    />
                                </CardBody>
                                <CardFooter
                                    className={classes.justifyContentCenter}
                                >
                                    <Button
                                        type={'submit'}
                                        color='primary'
                                        simple
                                        size='lg'
                                        block
                                        disabled={isFetching || !props.isValid}
                                    >
                                        Next
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Form>
                    );
                }}
            />
        );
    }
}
