export const types = {
    START_FETCHING:    'START_FETCHING',
    STOP_FETCHING:     'STOP_FETCHING',

    START_FETCHING_CHECK_USER:   'START_FETCHING_CHECK_USER',
    STOP_FETCHING_CHECK_USER:   'STOP_FETCHING_CHECK_USER',

    START_FETCHING_GET_ORG:    'START_FETCHING_GET_ORG',
    STOP_FETCHING_GET_ORG:     'STOP_FETCHING_GET_ORG',

    START_FETCHING_LOGIN:   'START_FETCHING_LOGIN',
    STOP_FETCHING_LOGIN:   'STOP_FETCHING_LOGIN',

    START_FETCHING_LOGIN_ORG:   'START_FETCHING_LOGIN_ORG',
    STOP_FETCHING_LOGIN_ORG:   'STOP_FETCHING_LOGIN_ORG',

    START_FETCHING_LOGOUT:  'START_FETCHING_LOGOUT',
    STOP_FETCHING_LOGOUT:   'STOP_FETCHING_LOGOUT'
};
