import { apply, put } from 'redux-saga/effects';
import { authActions } from 'bus/auth/actions';
import { api} from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { displayError } from '../../../../helpers';

export function* forgotPassWorker ({ payload }) {

    try {
        let { username } = payload;

        yield put(uiActions.startFetching());

        yield apply(api, api.auth.forgotPass, [username]);

        yield put(authActions.setIsEmailSent(true));

    } catch (error) {
        displayError('forgotPassWorker', error);

    } finally {
        yield put(uiActions.stopFetching());
    }
}
