import { Map } from 'immutable';
import types from './types';

const initialState = Map({
    user: null,
    loginStep: null,
    isEmailSent: false,
    isResetPassSubmitted: false,
    passwordPolicy: {},
    passwordPolicyError: '',
    resetPasswordErrors: '',
});

export default (state = initialState, action) => {
    switch (action.type) {

        case types.SET_USER_DATA:
            return state.set('user', action.payload);

        case types.SET_LOGIN_STEP:
            return state.set('loginStep', action.payload);

        case types.FILL_ORGANIZATIONS:
            return state.set('organizations', action.payload);

        case types.FILL_PASSWORD_POLICY:
            return state.set('passwordPolicy', action.payload);

        case types.GET_PASSWORD_POLICY_FAILED:
            return state.set('passwordPolicyError', action.payload);

        case types.SET_IS_EMAIL_SENT:
            return state.set('isEmailSent', action.payload);

        case types.SET_IS_RESET_PASS_SUBMITTED:
            return state.set('isResetPassSubmitted', action.payload);

        default:
            return state;
    }
};
