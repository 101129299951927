import Login from 'pages/AuthPages/Login';
import Forgot from 'pages/AuthPages/Forgot';
import Reset from 'pages/AuthPages/Reset';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Fingerprint from '@material-ui/icons/PersonAdd';
import { book } from '../config/book';
import Logout from '../pages/AuthPages/Logout';
import LogoutOrg from '../pages/AuthPages/LogoutOrg';

const authRoutes = [
    {
        path: book.login,
        name: 'Login',
        // short: 'Login',
        // mini: 'LG',
        icon: PersonAdd,
        component: Login
    },
    {
        path: book.forgot,
        name: 'Forgot Password',
        // short: 'Forgot',
        // mini: 'FG',
        icon: Fingerprint,
        component: Forgot
    },
    {
        path: book.reset,
        name: 'Reset Password',
        // short: 'Reset',
        // mini: 'LP',
        icon: Fingerprint,
        component: Reset
    },
    {
        path: book.logout,
        name: 'Logout',
        // short: 'Login',
        // mini: 'LG',
        icon: PersonAdd,
        component: Logout
    },
    {
        path: book.logoutOrg,
        name: 'Logout Organization',
        // short: 'Login',
        // mini: 'LG',
        icon: PersonAdd,
        component: LogoutOrg
    },
    {
        redirect: true, path: '/', pathTo: book.login, name: 'Login'
    }
];

export default authRoutes ;
