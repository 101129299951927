import { apply, put } from 'redux-saga/effects';
import { authActions } from 'bus/auth/actions';
import { api } from '../../../../config/api';
import { uiActions } from '../../../ui/actions';
import { displayError } from '../../../../helpers';

export function* getPasswordPolicyWorker ({ payload }) {

    try {
        yield put(uiActions.startFetching());

        let { resetToken } = payload;

        const response = yield apply(api, api.auth.getPasswordPolicy, [resetToken]);


        if (response.status >= 200 && response.status < 300) {
            let passwordPolicy = response.data.data;
            yield put(authActions.fillPasswordPolicy(passwordPolicy));
        }

    } catch (error) {
        if (error.response.status === 401) {
            yield put(authActions.getPasswordPolicyFailed(error));
        } else displayError('getPasswordPolicyWorker', error);

    }
    finally {
        yield put(uiActions.stopFetching());
    }
}
