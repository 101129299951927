/* TODO: This worker has to be removed when MobilizerSPA is updated:
    instead of login.agnitio.com/logout-organization, it should go to login.agnitio.com/login?source...  */
import React from 'react';
import {connect} from 'react-redux';
import history from '../../history';
import {book} from '../../config/book';

class LogoutOrgPage extends React.Component {

    componentDidMount() {
        history.push(book.login);
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

const connectedLogoutOrgPage = connect(null, mapDispatchToProps)(LogoutOrgPage);
export default connectedLogoutOrgPage;
