// ##############################
// // // LoginPage view styles
// #############################

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

const loginPageStyle = () => ({
    cardTitle: {
        ...cardTitle,
        color: '#FFFFFF'
    },
    textCenter: {
        textAlign: 'center'
    },
    justifyContentCenter: {
        justifyContent: 'center !important'
    },
    customButtonClass: {
        '&,&:focus,&:hover': {
            color: '#FFFFFF'
        },
        marginLeft: '5px',
        marginRight: '5px'
    },
    inputAdornment: {
        marginRight: '18px'
    },
    inputAdornmentIcon: {
        color: '#555'
    },
    cardHidden: {
        opacity: '0',
        transform: 'translate3d(0, -60px, 0)'
    },
    cardHeader: {
        marginBottom: '20px'
    },
    socialLine: {
        padding: '0.9375rem 0'
    },
    passField: {
        border: 'red solid 3px',
        transform: 'translate3d(0, -60px, 0)',
        color: 'red',
        margin: '100px'
    }
});

export default loginPageStyle;
