import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import { Formik, Field, Form,  } from 'formik';
import { username as usernameSchema } from '../../../bus/forms/shapes';
import UserIcon from '../../../assets/rainmaker-icons/Rainmaker_soft_icon_37_User.svg';
import CustomInputFormik from '../../../components/CustomInputFormik/CustomInputFormik';
import LinearProgress from '@material-ui/core/LinearProgress';
import SvgIcon from '@material-ui/core/SvgIcon';

export default class ForgotForm extends React.Component {
    render () {

        const { classes, username, handleSubmit, isFetching } = this.props;

        return (
            <Formik
                initialValues = { {'username': username }}
                validationSchema = {usernameSchema.schema}
                isInitialValid
                onSubmit={handleSubmit}
                render={ props => {
                    return (
                        <Form>
                            <Card login>
                                <CardHeader
                                    className={`${classes.cardHeader} ${classes.textCenter}`}
                                    color='info'
                                >
                                    <h4 className={classes.cardTitle}>Forgot Password</h4>
                                </CardHeader>
                                <CardBody>
                                    <Field
                                        name='username'
                                        labelText='Username...'
                                        formControlProps={{ fullWidth: true }}
                                        disabled = { isFetching }
                                        inputProps={{
                                            autoFocus: true,
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <SvgIcon component = 'i'>
                                                        <img src={UserIcon} alt='user'/>
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        component={CustomInputFormik}
                                    />
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter}>
                                    <Button
                                        type={'submit'}
                                        color='primary'
                                        simple size='lg'
                                        block
                                        disabled={isFetching || !props.isValid}
                                    >Next
                                    </Button>
                                </CardFooter>
                                {isFetching && <LinearProgress />}
                            </Card>
                        </Form>
                    );
                } }
            />
        );
    }
}
