import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import ResetForm from './Forms/ResetForm';
import {authActions} from '../../bus/auth/actions';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

class ResetPage extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            cardAnimaton: 'cardHidden',        // we use this to make the card to appear after the page has been rendered
            username: '',
        };
        this.handleSubmitResetPass = this.handleSubmitResetPass.bind(this);
    }

    componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function () {
                this.setState({ cardAnimaton: '' });
            }.bind(this),
            700
        );

        const { resetToken } = this.props.match.params;
        const { dispatch } = this.props;

        dispatch(authActions.getPasswordPolicyAsync( { resetToken } ));

    }

    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    handleSubmitResetPass = (formData) => {

        const { dispatch } = this.props;
        const { resetToken } = this.props.match.params;
        const { newPassword } = formData;

        if (resetToken) {
            dispatch(authActions.resetPassAsync({ resetToken, newPassword }));
        }
    };

    render() {
        const { classes, isFetching, passwordPolicy, isResetPassSubmitted, passwordPolicyError, resetPasswordErrors} = this.props;
        const { username } = this.state;

        if (isResetPassSubmitted) {
            return <Card login>
                <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color='info'
                >
                    <h4 className={classes.cardTitle}>Log in</h4>
                </CardHeader>
                <CardBody>
                    <Typography variant='body1' align='center'>
                        The password was successfully changed
                    </Typography>
                </CardBody>
                {isFetching && <LinearProgress />}
            </Card>;
        } else {

            if (passwordPolicyError) {
                return <Card login>
                    <CardBody>
                        <Typography variant='body1' align='center'>
                            {passwordPolicyError}
                        </Typography>
                    </CardBody>
                </Card>;}

            return <ResetForm
                classes={classes}
                handleSubmit={this.handleSubmitResetPass}
                username={username}
                isFetching={isFetching}
                passwordPolicy={passwordPolicy}
                resetPasswordErrors={resetPasswordErrors}
            />;}
    }
}

ResetPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

const mapStateToProps = (state) => {
    return {
        passwordPolicy:         state.auth.get('passwordPolicy'),
        passwordPolicyError:    state.auth.get('passwordPolicyError'),
        isResetPassSubmitted:   state.auth.get('isResetPassSubmitted'),
        resetPasswordErrors:    state.auth.get('resetPasswordErrors'),
    };
};

const connectedResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPage);
export default withStyles(loginPageStyle)(connectedResetPasswordPage);
