import axios from 'axios';
import {
    CHECK_USERNAME_API,
    GET_ORGANIZATIONS_API,
    LOGIN_API,
    RESET_PASS_API,
    AUTHENTICATE_API,
    FORGOT_PASS_API,
    LOGOUT_API,
    GET_PASSWORD_POLICY,
} from './constants';

axios.defaults.headers.common['Accept'] = 'application/json';

export const api = {
    get token() {
        return localStorage.getItem('token');
    },

    auth: {
        authenticate(token) {
            return axios({
                method: 'get',
                url: `${AUTHENTICATE_API}`,
                headers: {
                    'Auth-Token': token,
                },
                credentials: 'include',
            });
        },

        login(payload) {
            return axios({
                method: 'post',
                url: `${LOGIN_API}`,
                headers: {
                    'Content-Type': 'application/json',
                    source: payload.source || 'auth',
                    Authorization:
                        'Basic ' +
                        btoa(payload.username + ':' + payload.password),
                },
                withCredentials: true,
            });
        },

        checkUsername(username) {
            return axios({
                method: 'get',
                url: `${CHECK_USERNAME_API}/${username}`,
            });
        },

        getOrganizations(authToken, source) {
            return axios({
                method: 'get',
                url: `${GET_ORGANIZATIONS_API}`,
                headers: {
                    'Auth-Token': authToken,
                    source: source || 'spa',
                },
            });
        },

        loginOrg(authToken, orgId, source) {
            return axios({
                method: 'post',
                url: `${LOGIN_API}/${orgId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': authToken,
                    source: source || 'spa',
                },
            });
        },

        forgotPass(username) {
            return axios({
                method: 'post',
                url: FORGOT_PASS_API,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    username: username,
                },
            });
        },

        resetPass(resetToken, newPassword) {
            return axios({
                method: 'post',
                url: RESET_PASS_API,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    'reset-token': resetToken,
                    password: newPassword,
                },
            });
        },

        getPasswordPolicy(resetToken) {
            return axios({
                method: 'get',
                url: GET_PASSWORD_POLICY,
                headers: {
                    'reset-token': resetToken,
                },
            });
        },

        getCognitoLogoutUrl(token) {
            return axios({
                method: 'get',
                url: LOGOUT_API,
                headers: {
                    'Auth-Token': token,
                },
            });
        },

        logout(authToken) {
            const headers = { 'Content-Type': 'application/json' };
            authToken && (headers['auth-token'] = authToken);

            return axios({
                method: 'post',
                url: LOGOUT_API,
                headers: headers,
            });
        },
    },
};
