import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Field, Form, Formik } from 'formik';
import { password } from '../../../bus/forms/shapes';
import CustomInputFormik from '../../../components/CustomInputFormik/CustomInputFormik';
import LockIcon from '../../../assets/rainmaker-icons/Rainmaker_soft_icon_66_Password.svg';
import UserIcon from '../../../assets/rainmaker-icons/Rainmaker_soft_icon_37_User.svg';
import SvgIcon from '@material-ui/core/SvgIcon';
import history from '../../../history';
import { book } from '../../../config/book';

export default class LoginForm extends React.Component {

    render () {

        const { classes, handleSubmitLogin, isFetching, goBackToUsername, username } = this.props;
        return (
            <Formik
                initialValues = {password.shape}
                validationSchema = {password.schema}
                isInitialValid
                onSubmit={handleSubmitLogin}
                render={ props => {
                    return (
                        <Form>
                            <Card login>
                                <CardHeader
                                    className={`${classes.cardHeader} ${classes.textCenter}`}
                                    color='info'
                                >
                                    <h4 className={classes.cardTitle}>Log in</h4>
                                </CardHeader>
                                <CardBody>
                                    <Field
                                        name='username'
                                        labelText='Username'
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            autoFocus: true,
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <SvgIcon component = 'i'><img src={UserIcon} alt={'icon'}/></SvgIcon>
                                                </InputAdornment>
                                            ),
                                            onChange: () => goBackToUsername(),
                                            onClick: () => goBackToUsername(),
                                            value: username
                                        }}
                                        component={CustomInputFormik}
                                    />
                                    <Field
                                        name='password'
                                        labelText='Password'
                                        formControlProps={{ fullWidth: true }}
                                        disabled={isFetching}
                                        inputProps={{
                                            autoFocus: true,
                                            type: 'password',
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <SvgIcon component = 'i'>
                                                        <img src={LockIcon} alt='lock'/>
                                                    </SvgIcon>
                                                </InputAdornment>
                                            ),
                                        }}
                                        component={CustomInputFormik}
                                    />
                                </CardBody>
                                <CardFooter className={classes.justifyContentCenter}>
                                    <Button
                                        color='primary'
                                        simple
                                        size='lg'
                                        block
                                        onClick={ () => history.push(book.forgot)}
                                        disabled={isFetching}
                                    >
                        Forgot Password
                                    </Button>
                                    <Button
                                        type={'submit'}
                                        color='primary'
                                        simple size='lg'
                                        block
                                        disabled={isFetching || !props.isValid}
                                    >Next
                                    </Button>
                                </CardFooter>
                                {isFetching && <LinearProgress/>}
                            </Card>
                        </Form>
                    );
                } }
            />
        );
    }
}
