import { container } from '../../material-dashboard-pro-react';

const pagesStyle = theme => ({
    wrapper: {
        height: 'auto',
        minHeight: '100vh',
        position: 'relative',
        top: '0'
    },
    container: {
        ...container,
        zIndex: '4',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '100px'
        },
    },
    fullPage: {
        position: 'relative',
        minHeight: '100vh',
        maxHeight: '1000px',
        display: 'flex!important',
        margin: '0',
        border: '0',
        color: '#fff',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        height: '100%',
        [theme.breakpoints.down('xs')]: {
            minHeight: 'fit-content!important',
            padding: '80px 0',
        },
        '& footer': {
            position: 'absolute',
            bottom: '0',
            width: '100%',
            border: 'none !important'
        },
        '&:before,&:after': {
            display: 'block',
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            left: '0',
            zIndex: '2'
        }
    },
    authLogo: {
        padding: '0 20px 30px 30px ',
        width: '360px',
        overflow: 'hidden',
        '&,&:hover,&:focus': {
            color: 'inherit'
        }
    },
});

export default pagesStyle;
