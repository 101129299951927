import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';
import { authActions } from '../../bus/auth/actions';
import ForgotForm from './Forms/ForgotForm';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';
import Button from '../../components/CustomButtons/Button';
import history from '../../history';
import { book } from '../../config/book';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

class ForgotPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardAnimaton: 'cardHidden', // we use this to make the card to appear after the page has been rendered
            username: ''
        };
        this.handleSubmitForgot = this.handleSubmitForgot.bind(this);
    }
    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        this.timeOutFunction = setTimeout(
            function() {
                this.setState({ cardAnimaton: '' });
            }.bind(this),
            700
        );

        const { dispatch } = this.props;
        dispatch(authActions.setIsEmailSent(false));
    }
    componentWillUnmount() {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    handleSubmitForgot = formData => {
        const { dispatch } = this.props;
        const { username } = formData;
        dispatch(authActions.forgotPass({ username }));
    };

    render() {
        const { classes, isFetching, isEmailSent } = this.props;
        const { username } = this.state;

        if (!isEmailSent) {
            return (
                <ForgotForm
                    classes={classes}
                    isFetching={isFetching}
                    handleSubmit={this.handleSubmitForgot}
                    username={username}
                />
            );
        } else
            return (
                <Card login>
                    <CardHeader
                        className={`${classes.cardHeader} ${
                            classes.textCenter
                        }`}
                        color='info'
                    >
                        <h4 className={classes.cardTitle}>Log in</h4>
                    </CardHeader>
                    <CardBody>
                        <Typography variant='body1' align='center'>
                            The reset password link is sent to your email
                        </Typography>
                    </CardBody>
                    <CardFooter className={classes.justifyContentCenter}>
                        <Button
                            color='primary'
                            simple
                            size='lg'
                            block
                            onClick={() => history.push(book.login)}
                            disabled={isFetching}
                        >
                            Go To Login
                        </Button>
                    </CardFooter>
                    {isFetching && <LinearProgress />}
                </Card>
            );
    }
}

ForgotPage.propTypes = {
    classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        isEmailSent: state.auth.get('isEmailSent'),
        isFetching: state.ui.get('isFetching')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    };
};

const connectedForgotPasswordPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPage);
export default withStyles(loginPageStyle)(connectedForgotPasswordPage);
